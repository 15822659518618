.heading-common-font-size {
  font-size: 32px !important;
}

.checkout-offer-btn {
  height: 18px;
  font-size: 8px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
  justify-content: center;
  align-items: center;
  background: #000 !important;
  color: #fff;
  cursor: pointer;
  transition: all ease 1s;
  border: none !important;
}

.request-now-btn {
  font-size: 8px;
  margin-top: 10px !important;
  display: flex !important;
  justify-content: center !important; /* Center horizontally */
  align-items: center !important; /* Center vertically */
  /* width: 60%; */
  flex-direction: row;
  padding: 10px;
  border-radius: 10px;
  background: #ccc !important;
  color: #fff;
  cursor: pointer;
  transition: all ease 1s;
  border: none !important;
  margin: auto;
}

.request-now-btn-gray {
  color: #808080 !important;
}

.sub-heading-common-font-size {
  font-size: 25px;
  color: #848484;
}

.marquee-header {
  position: absolute;
  width: 106%;
  right: -6%;
}
.marquee-header-nologin {
  position: absolute;
  width: 119%;
  right: -19%;
}
.marquee-header-new {
  position: absolute;
  width: 106%;
  right: -9%;
}
.start-alignment {
  vertical-align: middle;
  display: flex;
  justify-content: center;
  gap: 3px;
  align-items: center;
}

.request-form-position {
  position: sticky;
  top: 10vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 12px;
}

.parnet-request-page .auth-validation-div {
  margin: 10px 15px 20px 18px ;
}

.custom-font-size-steps {
  font-size: 13px !important;
}

.faq-request-page .faq-requst-section {
  padding: 0px !important;
}
.faq-request-page .faq-requst-section h5 {
  padding: 0px !important;
  text-align: left;
}

/* my css */
.card-flex {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.custom-color-comp {
  color: #ff3201;
  font-family: 600 !important;
  font-weight: bold;
}

.top-border-chart {
  border-top: 1px solid #e2e2e2;
  padding: 0px;
  margin: 0px;
  padding: 6px !important;
}

.main-sub-heading-comp {
  font-size: 34px;
  font-weight: 600;
  padding-top: 15px;
}

.Comparison-main-heading {
  font-size: 11px;
  font-weight: 700;
}

.save-more-section {
  padding: 20px;
  background: #f3f4ff;
  font-size: 25px;
  font-weight: 600;
  border-radius: 5px;
}

.main-sub-heading-inspect {
  font-size: 34px;
  color: #808080;
  font-weight: 600;
}

.custom-request-page-filed::placeholder {
  color: #808080 !important;
  font-weight: 600;
}

.workshop-time-cutom-comp span {
  font-weight: normal !important;
}

.custom-height-comp {
  height: 40px;
  margin-bottom: 0px !important;
}

.request-text-save {
  display: none;
}

.flex-content {
  padding-top: 6px 0px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.request-page-slider .slick-prev,
.request-page-slider .slick-next {
  display: none !important;
}

.padding-guide-banner {
  color: #0451be !important;
}

.custom-color-guide-banner {
  color: black !important;
}

.custom-form-img {
  border-radius: 10px;
}

.custom-text-line-through {
  text-decoration: line-through;
}
.request-text-save-custom-mobile {
  display: none !important;
}

.show-section-top-request {
  margin-top: -25px;
}

.glare-animation-request {
  position: relative;
  background: #000;
  overflow: hidden;
  border-color: #000;
  width: 135px;
}
.cart-back-btn {
  display: none;
}

@keyframes shine {
  0% {
    left: -100px;
  }
  20% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
.slider-main .slick-next {
  right: 15px !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(255 255 255 / 75%);
}
.slider-main .slick-dots {
  bottom: 30px !important;
}
.slider-main .slick-prev {
  left: 15px !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(255 255 255 / 75%);
}
.slider-main .slick-dots li button:before {
  content: "-" !important;
  color: white !important;
  font-size: 90px !important;
  font-family: sans-serif;
  opacity: 1;
}
.slider-main .slick-dots li.slick-active button:before {
  color: rgb(255, 255, 84) !important;
}
.slider-main .slick-next:before {
  font-family: inherit;
  content: ">";
  font-size: 45px;
}
.slider-main .slick-prev:before {
  font-family: inherit;
  font-size: 45px;
  content: "<";
}
.glare-animation-request:before {
  content: "";
  position: absolute;
  width: 160px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 2s infinite linear; /* Animation */
}

.faq-request-page .faq-request {
  /* display: none !important; */
}
.custom-adjust-tpselling {
  margin-top: -10px !important;
}

.faq-font-size {
  font-size: 32px !important;
}

.custom-exit-popup {
  overflow: hidden;
}

.arrow-down-faq {
  vertical-align: text-bottom;
  margin-bottom: 1px !important;
}

.tpselling-slider-custom .slick-dots {
  display: none !important;
}

.no-scrollbar {
  width: 104vw;
  height: 100%;
}

.survey-form-iframe-container {
  height: 80vh;
  overflow: hidden;
  width: 100vw;
  margin-top: -70px;
}

.review-section-home-slider .slick-prev,
.review-section-home-slider .slick-next {
  display: none !important;
}

.custom-scroll-summary {
  height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.common_box {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}
.main_box {
  height: max-content;
}
.faq-color-all {
  color: #687188 !important;
}

.remove-offer {
  background-color: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%) !important;
}

.offer-slider-padding {
  padding-left: 7px;
}

.header-loader span {
  margin-top: -3px;
}

.checkout-header-loading {
  margin-top: 8px;
}

.offer-single-checkout {
  padding: 10px 0px;
}

.chekcout-billing-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: -16px !important; */
  gap: 5px;
}

.chekcout-billing-details p {
  font-size: 11px;
  opacity: 0.7;
  margin: 0;
}

.offer-applied-ui {
  text-decoration: line-through;
  opacity: 0.7;
  font-weight: 500;
}

.slider-non-level-up .slick-next {
  display: none !important;
}

.slider-non-level-up .slick-prev {
  top: 32px !important;
  left: -21px !important;
}

.slider-non-level-up .slick-prev:before {
  font-size: 14px !important;
}

.active-order-promotions .slick-prev {
  top: 28px !important;
  left: -7px !important;
  z-index: 100;
}
.active-order-promotions .slick-prev:before {
  font-size: 14px !important;
}

.active-order-promotions .slick-next {
  top: 28px !important;
  right: -7px !important;
}
.active-order-promotions .slick-next:before {
  font-size: 14px !important;
}

.active-order-promotions .slick-next {
  display: block !important;
}
.slider-level-up .slick-prev {
  display: none !important;
}

.slider-level-up .slick-next {
  top: 32px !important;
  right: -20px !important;
}

.slider-level-up .slick-next:before {
  font-size: 14px !important;
}

.chekcout-billing-details p:last-child {
  margin: 0px;
}
.price-strip-mb {
  height: 100%;
  align-items: center;
}
.price-strip-height {
  /* display: flex;
  align-items: center;
  min-height: 56px; */
}
@keyframes btn-dot-view-web {
  0% {
    transform: scale(1.5);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}

@keyframes pulse-2 {
  0% {
    transform: scale(5);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}

.span-layer-view-web-blue {
  background-color: #fff;
  z-index: -1;
  opacity: 1;
  animation: pulse-2 1s ease-in infinite;
}

.btn-dot-view-web {
  width: 20px; /* Add your desired width and height */
  overflow: hidden;
  height: 20px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: rgb(0, 230, 96);
  z-index: -1;
  border-radius: 20px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -khtml-border-radius: 100px;
  opacity: 1;
  animation: btn-dot-view-web 1s ease-out infinite;
  border: none;
}
.relative-btn-view-more {
  position: absolute;
  margin: 0px auto;
  width: 10px;
  height: 10px;
  left: -5px;
  top: -5px;
  display: flex;
}
.relative-btn-view-more-sidebar {
  position: absolute;
  margin: 0px auto;
  width: 10px;
  height: 10px;
  left: 56px;
  top: 6px;
  display: flex;
}

.span-layer-view-more {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;

  -webkit-animation: sploosh 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation-iteration-count: infinite;
}

.span-layer-view-more:nth-child(2) {
  -webkit-animation-delay: 0.33s;
  -webkit-animation-duration: 2.2s;
}

.btn-dot-view-more {
  border: 0;
  margin: 0px auto;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  display: flex;
  background-color: rgb(0, 230, 96,1);
  /* background-color: rgb(5, 139, 61); */
  padding: 2px;
  -webkit-animation: pulse 2s ease-out;
  -webkit-animation-iteration-count: infinite;
}

.custom-height-sec {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.offerPara{
  color: black;
  font-size: 12px;
  margin-top: -15px;
}

.request-rest-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  text-decoration: underline;
  color: #0451be;
  cursor: pointer;
  margin-right: 20px;
  margin-top: -18px;
  margin-bottom: 0px;
  padding: 0px 0px;
}
.dashboard-view-btn {
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  color: #0451be;
  cursor: pointer;
  margin-right: 20px;
  margin-top: -18px;
  margin-bottom: 0px;
  padding: 0px 0px;
}

.hp-request-rest-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  text-decoration: underline;
  color: #0451be;
  cursor: pointer;
  margin-top: -25px;
  margin-bottom: 5px;
  /* padding: 0px 0px; */
}

.custom-width-banner {
  /* max-width: 1490px; */
  max-width: 1550px;
}

.request-now-btn-gray {
  color: #808080 !important;
}

.workshop-margin-map {
  right: 96px !important;
}

.pay-now-order {
  border-radius: 5px !important;
}

.pay-now-order-active {
  background: #ff3201 !important;
}
.bg-none-order {
  background: none !important;
}
.custom-estimate-order {
  background: none !important;
  border: 2px solid #ff3201;
  color: #000;
  border-radius: 5px !important;
}

.custom-estimate-order :hover {
  background: none !important;
}

.custom-color-order {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

@media only screen and (max-width: 450px) {
  .max-width-quotation-form {
    width: 100% !important;
  }
  
  /* DETAILED INSPECTION FINDINGS TABLE css */
  .hp-request-rest-btn {
    margin-right: 5px !important;
  }

  .custom-order-staus {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .custom-order-staus p {
    margin-bottom: 0px !important;
  }

  .order-page-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pay-now-order {
    margin-top: 10px !important;
  }
  .mobile-bottom-sr-select {
    flex-wrap: wrap;
  }

  .custom-height-sec {
    height: fit-content;
  }

  .mobile-oil-change-cnt {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 4px 10px;
    border-radius: 5px;
    margin-bottom: 7px;
    background: #e8eef3;
    margin-top: -3px;
  }
  .mobile-oil-change-cnt .oil-brand-slnt {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  .mobile-oil-change-cnt .oil-brand-slnt span {
    font-size: 11px;
    color: #686868;
    font-weight: 500;
    padding-top: 4px;
  }
  .mobile-oil-change-cnt .oil-brand-slnt strong {
    padding-right: 16px;
    font-weight: 500;
    font-size: 13px;
    color: #3a63b8;
  }
  .mobile-oil-change-cnt
    .oil-brand-slnt
    .mobile-oil-change-cnt
    .oil-brand-slnt {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }

  .mobile-oil-change-cnt .oil-brand-slnt i {
    position: absolute;
    font-size: 13px;
    color: #3a63b8;
    top: 3px;
    right: 2px;
  }
  .mb-btmbar-vnt {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .mobile-bottom-direction {
    flex-direction: row-reverse !important;
  }

  .request-text-save-custom-mobile {
    display: block !important;
    margin: 10px auto 10px !important;
    font-size: 13px !important;
    padding: 5px 10px !important;
    border-radius: 10px !important;
  }
  .header-loader span {
    display: inline-block;
    margin-top: -6px;
    width: 40px;
    height: 40px;
    vertical-align: top;
  }
  .offer-slider-padding {
    padding-left: 21px;
    margin-top: 20px !important;
  }

  .form_inspection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .response_tablegrid .table {
    width: 100%;
    display: block;
    overflow-y: scroll;
  }
  .response_tablegrid .table td,
  .response_tablegrid .table th {
    padding: 2px !important;
  }
  .response_tablegrid .table td,
  .response_tablegrid .table th {
    min-width: 60px;
    /* display: block; */
    font-size: 11px;
    vertical-align: middle;
    text-align: center;
    padding: 2px !important;
    background: #fff;
  }
  .response_tablegrid td textarea {
    padding: 2px;
    font-size: 11px;
  }

  .response_tablegrid ul li {
    text-align: center !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .cart-back-btn {
    display: none;
  }

  .common_box {
    box-shadow: none;
    border-radius: 0px;
  }

  .faq-font-size {
    font-size: 25px !important;
  }
  .remove {
    background-color: #ff3201;
  }
  .heading-common-font-size {
    font-size: 22px !important;
  }

  .main-sub-heading-inspect {
    font-size: 24px !important;
    text-align: center !important;
  }
  .inspation-heading-section {
    margin-top: 20px;
  }

  .request-page-slider .form-img {
    /* padding-left: 0px !important; */
  }
  .request-guide-section {
    /* margin-right: 10px !important; */
  }
  .custom-font-size-comp {
    font-size: 11px !important;
  }

  .main-request-page-home {
    padding: 0px !important;
    max-width: 1490px;
  }

  .padding-guide-request-sec {
    padding: 0px !important;
  }

  /* .request-ob-assure */

  .request-ob .lg-blue-bg-reqeust ul {
    flex-wrap: nowrap !important;
  }

  .request-ob .lg-blue-bg-reqeust ul li:nth-child(1) img {
    margin-top: 10px !important;
  }

  .request-ob ul li:nth-child(2) img {
    margin-top: 0px !important;
  }

  .request-ob ul li:nth-child(3) img {
    margin-top: 0px !important;
  }

  .request-ob ul li:nth-child(4) img {
    margin-top: 0px !important;
  }

  .request-ob .lg-blue-bg-reqeust {
    background: white !important;
    margin-bottom: 12px;
    /* margin-top: 46px !important; */
  }

  .request-ob .lg-blue-bg-reqeust ul li img {
    width: 44px !important;
  }

  .request-ob .obsure-span img.reqeust-ob-img {
    width: 110px !important;
    margin-top: -32px !important;
  }

  .request-ob .lg-blue-bg-reqeust ul li {
    padding-bottom: 5px !important;
  }

  .request-ob .request-ob-font {
    font-size: 8px !important;
    /* padding: 5px 5px !important; */
  }

  .request-ob .elem-assure-request {
    margin: 0px 2px !important;
  }

  .request-ob .request-span {
    height: 62px !important;
    width: 70px !important;
  }

  .right-arrow-banner-home {
    position: absolute;
    bottom: 10px;
    right: 8px;
  }

  .request-guide-section .super-car-div {
    margin-bottom: 0px !important;
  }

  .no-scroll {
    overflow: auto !important;
  }

  .request-text-save-custom-mobile {
    display: block;
    margin: 0px;
    padding: 6px 10px;
    text-align: right;
    /* text-decoration: underline; */
    width: max-content;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .request-guide-section .cnt-block {
    margin-top: 0px !important;
  }

  .flex-content {
    padding-bottom: 5px !important;
  }

  .margin-save-mobile {
    margin-top: 0px !important;
  }

  .save-more-section {
    font-size: 16px !important;
    margin-top: 20px;
    padding: 10px 35px !important;
  }

  .request-text-save {
    display: block;
    position: absolute;
    right: 30px;
    top: 22px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
  }

  /* .price-com-slider .slick-next,
  .price-com-slider .slick-prev {
    display: none !important;
  } */

  .card-flex {
    box-shadow: none !important;
    border-right: 1px solid #e2e2e2;
  }
  .Comparison-main-heading {
    font-size: 9px !important;
  }
  .card-flex .col {
    padding: 0px !important;
    padding: 1px !important;
  }

  .custom-color-guide-banner {
    padding: 0px 10px;
  }

  .padding-request-mobile .container-fluid {
    padding: 0px !important;
  }

  .main-sub-heading-comp {
    font-size: 25px !important;
    padding-top: 5px;
  }

  .request-guide-section {
    margin: 0px !important;
    margin-top: 20px !important;
  }

  .header-section-request-top {
    margin-top: -90px;
  }

  .form-img img {
    min-height: 200px;
    border-radius: 10px;
  }

  /* .tpselling-slider-custom .slick-dots li:nth-child(-n + 3) {
    display: none;
  } */

  /* .tpselling-slider-custom .slick-dots li:nth-last-child(-n + 2) {
    display: none;
  } */

  .sub-heading-common-font-size {
    font-size: 16px !important;
  }

  .marquee-header {
    width: 178%;
    right: -170px;
  }

  .new-request-header {
    line-height: 20px;
  }

  .subheading-new-request-page {
    margin-top: -8px !important;
    margin-bottom: 10px !important;
    color: grey;
  }
  .request-page-custom-fsize {
    font-size: 22px !important;
    font-weight: 600;
    margin-top: 20px;
    /* margin-bottom: -10px; */
  }

  .request-form-border {
    box-shadow: 0 0 6px 2px rgb(0 0 0 / 26%);
    padding: 14px 0;
    border-radius: 8px;
  }
}
@media only screen and (max-width: 1024px) {
  .Comparison-main-heading{
font-size: 12px;
  }
  .top-border-chart{
    font-size: 13px ;
  }
  .show-section-web .col-6{
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media only screen and (max-width: 380px) {
  .top-border-chart{
  font-size: 9px !important
}
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .parnet-request-page .auth-validation-div{
    font-size: 14px;
  }
  .request-form-desktop .select-control{
    font-size: 14px !important;
  }
}
.request-page-service-box {
  display: none;
}

.inspection-footer-button a {
  text-decoration: none;
}

.react-simple-image-viewer__close {
  top: 120px !important;
  right: 30px !important;
  color: white !important;
}

.inspection-footer-button {
  border: 0px;
  padding: 5px 38px 5px 12px;
  border-radius: 20px;
  background-color: white;
  border: 2px solid gray;
  position: relative;
  width: 52%;
  margin-bottom: 10px;
}
.bottom-div {
  position: fixed; /* or position: absolute; */
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.security-report-page {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 150px 200px;
}

.view-inspectioin-button {
  padding: 9px 10px !important;
}

.inspection-div {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.set-inspection-arrow {
  position: absolute;
  right: 8px !important;
  top: 10px !important;
  margin-top: -3px !important;
  font-size: 20px;
}

.request-page-slider img {
  max-height: 460px;
  object-fit: cover;
}
.no-ws-found {
  height: calc(100vh - 363px) !important;
  font-size: 13px;
  text-align: center;
  opacity: 0.4;
}
.levelup-left {
  right: 8px;
}
.levelup-right {
  left: 8px;
}
.mns-tp {
  margin-top: -10px;
}

.workshop-view-btn {
  border-radius: 55px;
  border: 2px solid #0451be;
  background: linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
  font-weight: normal;
  font-size: 14px;
  width: 200px;
  height: 40px;
  color: #fff;
  padding: 0px 20px;
  line-height: 37px;
}

.offer-div-web .wd-orange-block {
  height: 100%;
}
.shake-animation {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.show-mobile {
  display: none;
}
.qCommonModal .modal-content{
margin: 10px !important;
margin-right: 10px !important;
}
@media only screen and (max-width: 450px) {
  .show-mobile {
    display: block;
  }
  .labor-icon-webshow {
    display: none;
  }

  .mns-tp {
    margin-top: -4px !important;
    margin-bottom: 4px;
  }
  .offer-div-web {
    margin-bottom: 25px;
  }
  .workshop-view-btn {
    width: auto !important;
  }

  .workshop-btn-sec {
    max-width: max-content !important;
  }

  .levelup-left {
    right: 0px !important;
  }
  .levelup-right {
    left: 0px !important;
  }
  .no-ws-found {
    height: calc(100vh - 363px) !important;
    font-size: 13px;
    text-align: center;
    opacity: 0.4;
  }

  .levelup-mobile-slider {
    margin-top: 15px;
  }

  .slider-level-up .slick-track {
    left: 0px !important;
  }

  .no-scroll::-webkit-scrollbar {
    width: 0px;
  }

  .chekcout-sidebar-hide {
    display: none;
  }

  .security-report-page {
    padding: 100px 0px;
  }

  .inspection-footer-button {
    width: 75% !important;
    margin-bottom: 10px;
  }
  .inspect-report-page {
    margin-top: -65px !important;
  }
  .request-page-slider img {
    width: 94vw !important;
  }
  .header-section-request-top {
    margin-top: -90px;
    padding-right: 0px !important;
  }
  .request-form-border {
    margin-right: 3px !important;
  }
  .margin-top-for-request-page {
    margin-top: -20px !important;
  }

  .request-page-comparison-mobile {
    display: flex !important;
  }

  /* .request-page-table-rows {
    height: 35px;
  } */
  .top-border-chart{
    font-size: 11px !important;
  }
  .ob-assure-new-request {
    margin-top: -22px !important;
  }
  .ob-assure-new-request img {
    width: 71px !important;
    height: 99px;
  }

  .lg-blue-bg-reqeust .elem-assure-request h5 {
    padding-top: 0px;
    line-height: 12px;
  }
  .request-page-service-box {
    display: flex;
  }
  .request-page-service-box ul {
    display: flex;
    overflow-y: hidden;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
  }

  .request-page-service-box ul::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .request-page-service-box ul li {
    border-radius: 5.632px;
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    background: linear-gradient(
      180deg,
      rgb(20 125 193 / 15%) 0%,
      rgba(255, 231, 231, 0) 100%
    );
    margin-right: 5px;
    width: 80px;
    min-height: 70px;
    max-height: 95px;
    padding: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 18px;
    cursor: pointer;
  }

  .request-page-service-box ul li strong {
    line-height: 150%;
    font-family: Gilroy, sans-serif;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    margin-bottom: 10px;
    color: #696969;
    padding: 0;
    padding-top: 6px;
    width: 73px;
    display: block;
  }

  .request-page-service-box ul li a {
    height: 109px;
    display: block;
  }

  .request-page-service-box li a img {
    width: 45px;
    height: 48px;
    object-fit: contain;
    margin: auto;
  }

  .container-floating-button {
    display: block;
  }

  .request-page .slick-list {
    padding: 0px 10px 0 0 !important;
  }
}
@media only screen and (max-width: 390px){
  .top-border-chart{
    font-size: 8px !important;
  }
}
.next-arrow-slick-slider {
  font-size: 25px;
  position: absolute;
  right: -10px;
  top: 50%;
  z-index: 10;
}

.next-arrow-slick-slider::before {
  content: "";
}

.prev-arrow-slick-slider {
  font-size: 25px;
  position: absolute;
  left: -10px;
  top: 50%;
  z-index: 10;
  display: none !important;
}

.prev-arrow-slick-slider::after {
  content: "";
}

.container-floating-button {
  position: fixed;
  float: none;
  text-align: center;
  right: 0;
  bottom: 10%;
  display: none;
}

.request-cta {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid red;
  background-color: red;
  color: #fff;
  transition: width 0.7s;
}
.request-cta:hover {
  width: 180px;
  transition: width 0.7s;
}

.request-cta i {
  opacity: 1;
  transition: opacity 0.5s;
}

.request-cta:hover i {
  opacity: 0;
  transition: opacity 0.5s;
}

.request-cta .button-text {
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  width: 100%;
  left: 0;
}

.request-cta:hover .button-text {
  opacity: 1;
  transition: opacity 0.5s;
}

.subheading-new-request-page {
  margin-top: -8px !important;
  margin-bottom: 10px !important;
  color: grey;
}
.request-page-service-box.main-sub-heading-comp.service {
  font-size: 15px !important;
}
/* .relative-btn-view-more {
  position: absolute;
  margin: 0px auto;
  margin-top: 2px;
  width: 10px;
  height: 10px;
  left: -5px;
  top: -5px;
  /* display: -webkit-flex; */
/* display: -moz-box; */
/* display: flex;
} */
/* .btn-dot-view-more {
  border: 0;
  margin: 0px auto;
  margin-top: 2px;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  display: flex;
  background-color: rgba(0, 230, 96, 1);
  padding: 2px;
  -webkit-animation: pulse 2s ease-out;
  -webkit-animation-iteration-count: infinite;
}

.span-layer-view-more {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  -webkit-animation: splooshmodg 2s cubic-bezier(0.165, 0.84, 0.44, 1);

  -webkit-animation-iteration-count: infinite;
} * */

@-webkit-keyframes splooshmodg {
  0% {
    box-shadow: 0 0 0 0px #0451be;
    background: #0451be;
  }
  80% {
    background: rgba(223, 66, 66, 0);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(223, 66, 66, 0);
  }
}
/* ------cart-animation-------- */

@-webkit-keyframes splooshmod {
  0% {
    box-shadow: 0 0 0 0px rgb(0, 230, 96);
    background: rgb(0, 230, 96);
  }
  80% {
    background: rgba(223, 66, 66, 0);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(223, 66, 66, 0);
  }
}

.relative-btn-view-cart {
  position: absolute;
  margin: 0px auto;
  width: 7px;
  height: 7px;
  right: 4px;
  top: 6px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}
.btn-dot-view-cart {
  border: 0;
  margin: 0px auto;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  z-index: 99;
  -webkit-animation: pulse 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  padding: 2px;
}

.span-layer-view-cart {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  -webkit-animation: splooshmod 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation-iteration-count: infinite;
}

.pay-now-btn-disable {
  background: #a6a6a6;
  color: #000;
  border: none;
}
.workshop-price-difference-low-del {
  font-size: 12px;
  font-weight: 500;
}
@media screen and (min-width: 1400px) {
  .marquee-header {
    position: absolute;
    width: 104%;
    right: -4%;
  }
  .marquee-header-nologin {
    width: 117%;
    right: -17%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .marquee-header {
    width: 102% !important;
    right: -2% !important;
  }
}
@media screen and (max-width: 768px){
  .request-form-desktop .select-control{
    font-size: 16px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .main-request-page-row {
    flex-direction: column;
  }
  .header-section-request-top {
    max-width: 100%;
  }
  .request-form-position-col {
    max-width: 100%;
  }
}
.checkout-page-styles {
  background-color: "red";
}
@media screen and (max-width: 500px) {
  .slider-main .slick-prev {
    z-index: 1;
  }
  .slider-main .slick-prev {
    width: 22px;
    height: 22px;
  }
  .slider-main .slick-next {
    width: 22px;
    height: 22px;
  }
  .slider-main .slick-prev:before {
    font-size: 18px;
  }
  .slider-main .slick-next:before {
    font-size: 18px;
  }
  .slider-main .slick-dots li button:before {
    font-size: 60px !important;
  }
  .slider-main .slick-dots {
    display: none !important;
  }
}
@media screen and (min-width: 501px) and (max-width: 768px) {
  .slider-main .slick-prev {
    z-index: 1;
  }
  .slider-main .slick-prev {
    width: 25px;
    height: 25px;
  }
  .slider-main .slick-next {
    width: 25px;
    height: 25px;
  }
  .slider-main .slick-prev:before {
    font-size: 20px;
  }
  .slider-main .slick-next:before {
    font-size: 20px;
  }
  .slider-main .slick-dots li button:before {
    font-size: 60px !important;
  }
  /* .slider-main .slick-dots{
    display: none !important;
  } */
}
/* @media screen and (min-width: 1581px) {
  .request-page-slider img{
    max-height: initial !important;
  }
} */

@media (min-width: 576px) {
  .common-confirmation-modal .modal-dialog {
    max-width: 600px !important;
  }
}
