.quotation-master-item {
  background-color: #0451be;
  border-radius: 25px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #fff;
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
  }

  hr {
    background: #fff;
    margin: 0px;
  }

  .d-flex h5:nth-child(2) {
    // font-size: 16px;
    line-height: 23px;
  }

  .clock {
    display: flex;
    background: #fff;
    width: fit-content;

    .clock-tick {
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 2px;

      .clock-div {
        border: 1px solid black;
        margin: 5px;
        border-radius: 5px;
        height: 60px;
        width: 60px;
        display: -moz-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f4ff;
        font-size: 35px;
        font-weight: bold;
      }
    }
  }

  .arrow-icon {
    font-size: 30px;
  }

  .quote-request-list {
    background-color: #f3f4ff;
    border-radius: 15px;

    h6,
    p {
      color: #000;
      margin-bottom: 0px;
    }

    .place-end {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .call-button {
      font-size: 13px;
      font-weight: 500 !important;
      padding: 0px !important;
      text-decoration: underline;
    }

    .info-text {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .min-width-130 {
    min-width: 130px;
  }

  .small-text {
    font-size: 15px;
  }

  .normal-text {
    font-size: 16px;
  }

  .request-button {
    background: linear-gradient(
      0deg,
      rgba(247, 146, 34, 1) 0%,
      rgba(255, 50, 1, 1) 100%
    );
  }

  @media (max-width: 769px) {
    .oBQuotationItemGap {
      margin-top: 8px;
    }

    // p {
    //   font-size: 12px;
    // }

    .validity-clock {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin: 20px 0px;
    }

    .desktop-view {
      display: none;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
    }

    h6 {
      font-size: 13px;
      font-weight: 500;
    }

    .d-flex h5:nth-child(2) {
      font-size: 13px;
    }

    .clock-div {
      height: 50px !important;
      width: 54px !important;
      font-size: 32px !important;
    }
  }

  @media (min-width: 991px) and (max-width: 1280px) {
    .validity-clock {
      flex-direction: column;
      right: 54px;
    }
  }

  @media (max-width: 1200px) {
    h5 {
      font-size: 14px;
      font-weight: 500;
    }

    .d-flex h5:nth-child(2) {
      font-size: 14px;
    }
  }
}

.quotation-car-search {
  border-radius: 5px;
  font-weight: 600;
  color: #333333;
  border: 1px solid #979393 !important;
  padding: 0 10px;
  background-color: #fff;
  input {
    border: none !important;
    padding: 5px !important;
    padding-left: 0px !important;
  }
  i {
    color: #333;
  }
}
