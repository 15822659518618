.bee-page-container {
  overflow: hidden;
  overflow-y: scroll;
}
.bee-row-1 .bee-row-content,
.bee-row-2 .bee-row-content,
.bee-row-3 .bee-row-content {
  background-color: #0451be;
  color: #000;
  background-repeat: no-repeat;
}
.bee-row-1,
.bee-row-2,
.bee-row-2 .bee-row-content,
.bee-row-3 {
  background-repeat: no-repeat;
}
.bee-row-1 .bee-col-1,
.bee-row-2 .bee-col-1,
.bee-row-2 .bee-col-2,
.bee-row-3 .bee-col-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.bee-row-1 .bee-col-1 .bee-block-8 a,
a {
  color: #8a3c90;
}
.bee-row-content {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}

.bee-row-content .bee-col-w6 {
  flex-basis: 50%;
}
.bee-row-content .bee-col-w12 {
  flex-basis: 100%;
}
.bee-divider,
.bee-image {
  overflow: auto;
}
.bee-divider .center,
.bee-image .bee-center {
  margin: 0 auto;
}
.bee-icon {
  display: inline-block;
  vertical-align: middle;
}
.bee-image img {
  display: block;
  width: 100%;
}
.bee-paragraph,
.bee-text {
  overflow-wrap: anywhere;
  color: "white";
}
.bee-row-1 .bee-col-1 .bee-block-3 {
  padding: 20px;
  text-align: center;
  width: 100%;
}
.bee-row-2 .bee-col-1 .bee-block-2 {
  text-align: left;
  padding: 10px;
}
.bee-row-3 .bee-col-1 .bee-block-1 {
  color: #9d9d9d;
  font-family: inherit;
  font-size: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}
.bee-row-1 .bee-col-1 .bee-block-8 {
  color: #fff;
  direction: ltr;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 120%;
  text-align: center;
}
.bee-row-1 .bee-col-1 .bee-block-8 p:not(:last-child) {
  margin-bottom: 12px;
}
.bee-row-3 .bee-col-1 .bee-block-1 .bee-icon-image {
  padding: 5px 6px 5px 5px;
}
.bee-row-3 .bee-col-1 .bee-block-1 .bee-icon:not(.bee-icon-first) .bee-content {
  margin-left: 0;
}
.bee-row-3 .bee-col-1 .bee-block-1 .bee-icon::not(.bee-icon-last) .bee-content {
  margin-right: 0;
}
.bee-heading-style {
  color: #fff;
  direction: ltr;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 120%;
  text-align: center;
  margin-top: 0;
  text-decoration: underline;
  margin-bottom: 15px;
}
.bee-text-content {
  line-height: 120%;
  font-size: 12px;
  font-family: inherit;
  color: #fff;
}
.bee-paragraph-style {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
.bee-content-22 {
  font-family: inherit;
  font-size: 16px;
  background-color: #fff;
  border-radius: 4px;
  border-top: 0 solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 0 solid transparent;
  color: #ff3201;
  padding: 0 15px;
  width: auto;
  max-width: 100%;
  direction: ltr;
  display: inline-block;
}

.bee-row-content:not(.no_stack) {
  display: block;
}
.bee-row-1 .bee-col-1 .bee-block-8 {
  text-align: center !important;
  font-size: 23px !important;
}
.bee-row-1 .bee-col-1 .bee-block-4,
.bee-row-1 .bee-col-1 .bee-block-5,
.bee-row-1 .bee-col-1 .bee-block-7,
.bee-row-2 .bee-col-1 .bee-block-1 {
  padding: 5px !important;
}
.bee-row-1 .bee-col-1 .bee-block-7 .bee-separator,
.bee-row-2 .bee-col-1 .bee-block-1 .bee-separator {
  margin: auto !important;
  float: right !important;
}
.bee-row-1 .bee-col-1 .bee-block-3,
.bee-row-1 .bee-col-1 .bee-block-6 {
  padding: 10px !important;
}
.bee-row-1 .bee-col-1 .bee-block-6 img,
.bee-row-2 .bee-col-2 .bee-block-1 img {
  float: none !important;
  margin: 0 auto !important;
}
.bee-row-1 .bee-col-1 .bee-block-3 h1 {
  font-size: 26px !important;
}
.bee-row-2 .bee-col-1 .bee-block-2,
.bee-row-2 .bee-col-1 .bee-block-2 .bee-button-content {
  text-align: center !important;
}
.bee-row-2 .bee-col-2 .bee-block-1 {
  padding: 0 !important;
}

.qr-code-download {
  margin-bottom: 15px;
}

.bee-paragraph {
  padding: 10px 20px;
}

.call-button-download-popup {
  color: #fff !important;
  font-weight: 400;
  font-size: 15px;
}
