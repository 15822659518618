.new-common-input {
  border-radius: 5px;
  font-weight: 600;
  color: #333333;
  border: 1px solid #979393 !important;
  padding: 0 10px;
  ::placeholder {
    color: #808080 !important;
    font-weight: 600;
  }
}

.new-background-gradient {
  background: linear-gradient(180deg, #ff3201 0%, #f79222 100%);
}

.light-blue-new {
  background-color: #f3f4ff;
}

.new-fw-bold {
  font-weight: bold;
}

.table-scrollable {
  overflow-y: auto;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .text-center-mobile {
    text-align: center !important;
  }
  .mobile-top-80 {
    margin-top: -64px;
  }
  .mobile-font {
    font-size: 14px;
  }

  .table-scrollable {
    max-height: 300px; /* Adjust the height as needed */
    overflow-y: auto;
  }
}

.new-progress-bar {
  position: relative;
  width: 500px;
  height: 35px;
  background-color: #fff;
  margin: 0 10px;
  border-radius: 1.5em;
  color: white;
  width: 90%;
}

.new-progress-bar-inside {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  bottom: 0.5em;
  width: 0px;
  min-width: 5%;
  max-width: calc(100% - 1em);
  background-color: rgb(0, 230, 96, 1);
  border-radius: 1em;
}
