.wd-lg-blue-bg {
  border-radius: 10px;
  background: #f3f4ff;
  padding: 12px 20px;
  height: 100px;
}

.wd-main-block {
  padding: 30px 15px;
}

.wd-lg-blue-bg ul {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.wd-elem-assure {
  display: flex;
  align-items: center;
}

.wd-elem-assure span {
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  width: 74px;
  height: 74px;
}

.wd-elem-assure img {
  width: 55px;
}

.wd-elem-assure h5 {
  font-size: 14px;
  margin: 0px;
  padding: 0px 0 0 20px;
  font-weight: 500;
  color: #343434;
}

.wd-assure-block span {
  background: transparent;
}

.wd-assure-block img {
  width: 170px;
}

.wd-assure-block {
  height: 73px;
}

.wd-assure-block span {
  height: auto;
  width: auto;
}

.wd-lg-blue-bg ul li:nth-child(1) img {
  width: 58px;
}

.wd-lg-blue-bg ul li:nth-child(2) img {
  width: 58px;
  margin-top: -4px;
}

.wd-lg-blue-bg ul li:nth-child(4) img {
  width: 60px;
  margin-top: -2px;
}

.wd-lg-blue-bg ul li:nth-child(5) img {
  width: 60px;
  margin-top: -2px;
}

.wd-brand-txt-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.wd-br-logo {
  width: 200px;
  padding: 10px;
  height: 115px;
  text-align: center;
  vertical-align: middle;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
}

.wd-br-desc h1 {
  font-size: 30px;
  font-weight: 600;
}

.wd-br-desc label {
  font-size: 20px;
  color: #585d7a;
  margin-bottom: 0px;
}

.wd-br-desc label i {
  padding-right: 5px;
}

.wd-br-logo img {
  max-width: 100%;
  /* Make the logo image responsive */
  max-height: 100%;
  /* Make the logo image responsive */
}

.wd-wp-header-bar {
  padding: 30px 0px;
}

.wd-assureicon-block ul {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-top: 10px;
}

.wd-assureicon-block ul li label {
  width: 86px;
  height: 81px;
  text-align: center;
  border-radius: 10px;
}

.wd-assureicon-block ul li label img {
  padding-top: 13px;
}

.wd-assureicon-block ul li:nth-child(1) label {
  background: #efe;
  color: #2e922c;
}

.wd-assureicon-block ul li:nth-child(2) label {
  background: #f1f8ff;
  color: #0451be;
}

.wd-assureicon-block ul li span {
  display: block;
  font-size: 12px;
  font-weight: 700;
  padding-top: 4px;
}

.wd-assureicon-block ul {}

.wd-lfwsh-block {
  height: 514px;
  text-align: center;
  vertical-align: middle;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
}

.wd-lfwsh-block img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.wd-sl-image-bl {
  text-align: center;
  width: 100%;
  height: 246px;
  margin-bottom: 25px;
  vertical-align: middle;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  position: relative;
}

.wd-sl-image-bl img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.wd-gallery-view-link {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.5);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wd-gallery-modal .modal-lg {
  max-height: 800px;
  max-width: 900px;
}

.wd-gallery-modal .modal-lg .slick-slide img {
  max-width: 900px;
  object-fit: contain !important;
  max-height: 70vh;
}

.wd-gallery-modal .modal-content {
  height: 100%;
}

.wd-close-btn-bl {
  position: absolute;
  right: 0px;
  z-index: 100;
  background: #000 !important;
  color: #fff;
  width: 40px;
  height: 40px;
}

.gallery-view-slide button.slick-arrow.slick-next {
  margin-right: 40px;
}

.gallery-view-slide button.slick-arrow.slick-prev {
  margin-left: 40px;
  z-index: 1;
}

.gallery-view-slide button.slick-arrow.slick-prev::before {
  color: white !important;
  font-size: 30px !important;
}

.gallery-view-slide button.slick-arrow.slick-next:before {
  color: white !important;
  font-size: 30px !important;
}

.wd-gallery-modal .carousel-control-next,
.wd-gallery-modal .carousel-control-prev {
  width: 4%;
}

.wd-mp-frame {
  border-radius: 10px;
  overflow: hidden;
}

.wd-rating-bar {
  display: flex;
  align-items: center;
}

.wd-rating-bar label {
  margin: 0px;
  padding: 0px 6px;
  background: #ffe8d0;
  color: #ff3201;
  font-weight: 600;
  border-radius: 4px;
  margin-right: 5px;
}

/*---------- star rating ----------*/

.wd-front-stars,
.wd-back-stars,
.wd-star-rating {
  display: flex;
  gap: 2px;
}

.wd-star-rating {
  font-size: 16px;
}

.wd-back-stars {
  color: #a7a7a7;
  position: relative;
}

.wd-front-stars {
  color: #ffcd1c;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 0.5s;
}

.wd-ms-header {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 7px;
}

.wd-shadow-widget {
  border-radius: 10px;
  border: 1px solid #d5e2eb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 15px;
}

.wd-rating-div {
  padding-bottom: 35px;
  padding-top: 10px;
}

.wd-opn-inner {
  font-size: 14px;
}

.wd-opn-inner {}

.wd-csdropdown {
  z-index: 85;
}

.wd-wrhrs {
  position: absolute;
  right: -22px;
  top: 0px;
}

.wd-opn-inner span {
  position: relative;
}

.wd-csdropdown {
  position: absolute;
  right: 10px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.13);
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  left: 0px;
}

.wd-csdropdown ul {
  display: flex;
  list-style: none;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}

.wd-csdropdown ul li {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 13px;
}

.wd-csdropdown ul li strong {
  font-weight: 600;
}

.wd-opn-hr-div {
  padding-bottom: 35px;
}

.wd-amen-block-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  font-size: 13px;
}

.wd-amen-block-list li {
  border-radius: 88px;
  font-style: normal;
  background: #f4f8ff;
  height: 30px;
  padding: 2px 15px;
  color: #4385f5;
  margin-bottom: 15px;
}

.wd-amen-block-list li img {
  padding-left: 4px;
  padding-right: 4px;
}

.wd-amen-block-list li i {
  font-style: normal;
  vertical-align: middle;
  margin-top: 3px;
  display: inline-block;
}

.wd-lfmp-blck {
  min-height: 388px;
}

.wd-mapview-wrs {
  padding: 10px 0px 40px;
}

.wd-dl-header {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0px;
}

.wd-desc-wrs p {
  font-size: 14px;
  color: #464646;
}

.wd-nr-wrsh-list {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  position: absolute;
  padding: 10px;
  right: 35px;
  bottom: 28px;
}

.wd-wrslc {}

.wd-crloc img {
  width: 35px;
}

.wd-nr-wrsh-list i {
  width: 40px;
  display: inline-block;
  text-align: center;
}

.wd-nr-wrsh-list li {
  padding: 10px 10px;
}

.wd-nr-wrsh-list {
  font-size: 14px;
  color: #777777;
}

.wd-desc-wrs {
  margin-bottom: 25px;
}

.wd-wps-service-area {
  margin-bottom: 25px;
}

.wd-serv-list a {
  text-align: center;
  display: block;
  margin-bottom: 30px;
  margin-top: 30px;
}

.wd-serv-list img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0px auto;
  object-fit: contain;
}

.wd-mob-mac img {
  width: 70px;
}

.wd-serv-list span {
  display: inline-block;
  font-size: 13px;
  background: #ccf0ff;
  padding: 4px 20px;
  border-radius: 40px;
  margin-top: 10px;
  color: #343434;
}

.wd-serv-list-custom span {
  display: inline-block;
  font-size: 13px;
  /* background: #ccf0ff; */
  padding: 4px 20px;
  border-radius: 40px;
  margin-top: 10px;
  color: #343434;
}

.wd-orange-block {
  background: #fff5ea;
  border: 1px solid #ebcaa6;
  padding: 15px 15px 6px;
  border-radius: 10px;
}

.wd-orange-block .slick-prev,
.wd-orange-block .slick-next {
  display: none !important;
}

.wd-ofr-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 10px;
}

.no-data-offer {
  background: #fff;
  border-radius: 15px;
  height: 115px;
  text-align: center;
  padding-top: 20px;
}

.no-data-offer strong {
  display: block;
  font-weight: 500;
  color: #d4b392;
  padding-top: 5px;
}

.wd-ofr-header span {
  padding-left: 8px;
}

.wd-ofr-header h3 {
  font-size: 20px;
  font-weight: 600;
  color: #ff8f21;
  padding-left: 10px;
  margin-bottom: 0px;
}

.wd-ofr-slider-class {
  padding: 10px;
}

.wd-ofr-slider-inner {
  border-radius: 10px;
  position: relative;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff5ea;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 14px 15px;
}

.wd-ofr-slider-inner p {
  font-size: 14px;
  color: #555;
  font-weight: 500;
  padding-top: 9px;
}

.wd-ofr-slider-inner p span {
  font-weight: 700;
  color: #ff8f21;
}

.wd-ofr-slider-inner label {
  position: absolute;
  right: 10px;
  color: #75777b;
  font-size: 13px;
  bottom: 1px;
}

.wd-orange-block .slick-dots li {
  margin: 0 0px !important;
}

.wd-ofr-slider .slick-slider {
  margin-bottom: 30px;
}

.wd-light-blcall {
  background: #f1f4ff;
  border: 1px solid #c0cdee;
  padding: 45px 30px;
  border-radius: 10px;
}

.wd-clinfo {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 0px;
}

.wd-clinfo h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.wd-clinfo h4 span {
  color: #0451be;
  display: block;
}

.wd-clinfo h4 strong {
  color: #ff8f21;
  font-weight: 500;
}

.wd-clinfo img {
  margin-right: 14px;
}

.wd-inp-form-inner {
  position: relative;
}

.wd-inp-form-inner .fa-phone {
  position: absolute;
  top: 18px;
  left: 22px;
}

.wd-inp-form-inner input {
  height: 50px;
  border-radius: 50px;
  border-color: #0451be;
  padding-left: 50px;
}

.wd-inp-form-inner .btn {
  position: absolute;
  right: 3px;
  height: 46px;
  border-radius: 48px;
  top: 2px;
  background: linear-gradient(0deg, rgba(4, 81, 190, 1) 0%, rgba(12, 24, 146, 1) 100%);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  line-height: unset !important;
  color: #fff;
  font-weight: 600;
}

.wd-offer-call {
  margin-bottom: 20px;
}

.wd-auto-brand-section {
  background: #f3f4ff;
  padding: 50px 0px;
  margin-bottom: 120px;
}

.wd-at-br-logo {
  width: 92px;
  height: 92px;
  border-radius: 92px;
  background: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

.wd-at-br-logo img {
  max-width: 100%;
  max-height: 100%;
}

.wd-exp-auto {
  padding-bottom: 50px;
}

.wd-tc-block {
  font-size: 13px;
}
// .orkshop-block-island-ul{
//   position: relative !important;
// }
@media screen and (max-width: 960px) {
  .wd-lg-blue-bg ul li {
    width: 50%;
  }

  .wd-lg-blue-bg ul li img {
    width: 40px !important;
  }

  .wd-elem-assure h5 {
    font-size: 12px;
    padding-top: 7px;
  }

  .wd-elem-assure {
    display: block;
    text-align: center;
  }

  .wd-elem-assure.wd-assure-block span img {
    width: 143px !important;
    margin-top: -34px;
  }

  .wd-elem-assure span {
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
  }

  .wd-lg-blue-bg ul li:nth-child(5) img {
    margin-top: -6px;
  }

  .wd-elem-assure {
    margin-top: -5px;
  }

  .wd-elem-assure.wd-assure-block span {
    width: initial !important;
    height: initial !important;
    background: transparent;
  }

  .wd-lg-blue-bg ul li:nth-child(4) img {
    margin-top: -5px;
    margin-left: -2px;
  }

  .wd-lg-blue-bg ul li:nth-child(2) img {
    margin-top: -5px;
    margin-left: -4px;
  }
}

@media screen and (max-width: 768px) {
  .wd-desktop-assure {
    display: none;
  }
  .orkshop-block-island-ul{
    position: absolute !important;
  }
  .wd-csdropdown {
    right: -35px;
    left: auto;
  }

  .wd-main-block {
    padding: 30px 0px;
  }

  .wd-brand-txt-info {
    flex-wrap: wrap;
  }

  .wd-br-logo {
    width: 100%;
  }

  .wd-wp-header-bar {
    padding: 0px 0px 20px;
  }

  .wd-br-desc h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .wd-br-desc label {
    font-size: 16px;
  }

  .wd-assureicon-block ul li {
    width: 50%;
  }

  .wd-assureicon-block ul li label {
    width: 100%;
  }

  .wd-lfwsh-block {
    height: 200px;
    margin-bottom: 15px;
    border-radius: 15px;
  }

  .wd-sl-image-bl {
    height: 115px;
    border-radius: 15px;
    margin-bottom: 15px;
  }

  .wd-gall-block .col-6:nth-child(1),
  .wd-gall-block .col-6:nth-child(3) {
    padding-right: 8px;
  }

  .wd-gall-block .col-6:nth-child(2),
  .wd-gall-block .col-6:nth-child(4) {
    padding-left: 8px;
  }

  .wd-lfmp-blck {
    margin-bottom: 25px;
  }

  .wd-orange-block {
    margin-bottom: 25px;
  }

  .wd-light-blcall {
    padding: 30px 20px;
  }

  .wd-clinfo img {
    width: 50px;
  }

  .wd-serv-list a {
    margin-top: 0px;
  }

  .wd-at-br-logo {
    margin: 9px auto;
  }

  .wd-exp-auto {
    margin: 0px auto;
    padding-bottom: 15px;
  }

  .wd-auto-brand-section {}
}

/* extent pop up  */
.phbody {
  padding: 0px;
}

#intentmodal .modal-dialog {
  max-width: 645px;
}

.call-center-img img {
  width: 100%;
}

.txt-block-call h3 {
  font-weight: 300;
  font-size: 20px;
}

.txt-block-call h4 {
  font-weight: 700;
  font-size: 20px;
}

.txt-block-call p {
  font-size: 13px;
  color: #8d8d8d;
}

.phbody .row {
  margin: 0px;
}

.phbody .col-md-6 {
  padding: 0px;
}

.modalheader .close {
  position: absolute;
  right: 14px;
  font-size: 36px;
  top: 5px;
  z-index: 1;
}

.modalheader .close i {
  font-size: 23px;
}

.txt-block-call {
  text-align: center;
  text-align: center;
  padding-top: 50px;
}

.rightcol-block {
  margin-left: -20px;
  background: #fff;
  padding: 0px 3px 0px 23px !important;
}

.formlm {
  position: relative;
  padding-bottom: 25px;
}

.formlm i {
  position: absolute;
  left: 11px;
  color: #7a7a7a;
  top: 13px;
  font-size: 13px;
}

.formlm input {
  padding-left: 30px;
  font-size: 14px;
}

.invalid-input {
  border: 1px solid red;
}

.submitblockexp {
  padding: 10px;
  text-align: center;
}

.btnorng {
  background: #0451be;
  color: #fff;
  font-size: 14px;
  border-radius: 27px;
  padding: 8px 46px;
  font-weight: 600;
}

.form-call-block {
  padding: 16px 0px 0px;
}

@media screen and (max-width: 768px) {
  .call-center-img img {
    height: 211px;
    object-fit: cover;
    object-position: top;
  }

  .txt-block-call {
    padding-top: 19px;
  }

  .rightcol-block {
    margin-left: -9px;
    background: transparent;
  }
}

.f-12 {
  font-size: 13px;
}

.f-8 {
  font-size: 14px;
}

.custom-height-formlm {
  height: 70px;
}

.custom-fade:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.loader-custom {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  z-index: 998;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.car-rght-new {
  padding-left: 10px;
}

.cstp-bar {
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
  padding: 10px 10px 10px;
  // margin-right: 25px;
}

.bck-btn {
  background: transparent;
  border: 0px;
}

.bck-icon {}

.img-tp-rightbar {
  display: flex;
  align-items: center;
}

.car-rght-new h5 {
  font-size: 15px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  line-height: 10px;
  padding-top: 5px;
}

.img-tp-rightbar img {
  border: 1px solid #cccc;
  border-radius: 9px;
  padding: 2px;
}

.car-rght-new span {
  font-size: 13px;
  opacity: 0.8;
}

.bck-icon {
  font-size: 19px;
}

.exp-margin {
  margin-bottom: 30px;
}

.exp-margin input {
  width: 100%;
  display: block;
}

.exp-margin .text-danger {
  color: #dc3545 !important;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
}

.start-here-body .ServiceSelectionBox {
  padding: 30px 5px !important;
}

.viewall-btn-brand strong {
  display: block;
  font-weight: 500;
  color: #ff3201;
}

.viewall-btn-brand span {
  display: block;
  color: #ff3201;
}

.wd-gallery-wsh .gallery-view-slide .slick-track {
  height: 100%;
  display: flex;
  align-items: center;
  background: #000;
}

.drp-list-amn {
  background: #fff;
  border: 1px solid #dfdfdf;
  padding: 4px 10px;
  position: absolute;
  min-width: 140px;
  right: 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 7px 0px #ccc;
}
.orkshop-block-island-ul{
  min-width:  165px ;z-index:99; position: relative
}

.more-item-amenties {
  background: #d9e7ff;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  padding-right: 0px !important;
}

.workshop-block-island-ul>li {
  border: 1px solid #cccc;
  padding: 7px 10px 5px 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  // box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(77, 72, 72, 1) 0px 0px 0px 1px;
}

.amenities-list-sdbar ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  align-items: center;
}

.amenities-list-sdbar ul li img {
  width: 25px !important;
  margin-right: 5px;
  margin-top: -2px;
  margin-bottom: 3px;
}

.amenities-list-sdbar ul li {
  font-size: 12px;
  font-weight: 500;
  color: #4385f5;
  padding-right: 12px;
  position: relative;
}