// Shining Animation
.shine-animation-new {
  position: relative;
  overflow: hidden;
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.shine-animation-new::after {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(22, 182, 223, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0.5) 70%,
    rgba(47, 121, 177, 0) 100%
  );
  animation: shine 3s linear infinite !important;
}

.shine-animation-new-after::after {
  display: none;
}

@keyframes shine {
  100% {
    left: 100%;
  }
}

// Waves Animation

.progress-waves {
  position: relative;
  right: -30px;
  top: -20px;
  display: grid;
  place-items: center;
  span {
    width: 8px;
    height: 8px;
    background-color: rgb(5, 139, 61);
    border-radius: 100%;
    display: block;
    padding: 0px !important;
  }
}

@keyframes progresswave {
  from {
    border: 2px rgba(0, 230, 96, 0.5) solid;
    width: 6px;
    height: 6px;
    border-radius: 6px;
  }
  to {
    border: 2px rgb(0, 230, 96) solid;
    width: 30px;
    height: 30px;
    border-radius: 48px;
  }
}

.progress-waves::before,
.progress-waves::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 50% !important;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  animation: 1.2s linear infinite progresswave;
}

.progress-waves::after {
  animation-delay: 0.6s;
}

.centered-waves {
  top: 0;
  right: auto;
  left: auto;
}

.centered-waves::before,
.centered-waves::after {
  left: auto;
  right: auto;
}
