.sign-up-page {
  display: flex;
  justify-content: center;
  .custom-modal-signup {
    min-height: calc(100% - 3.5rem);
    max-width: 500px;
    margin: 1.75rem auto;
    position: relative;
    width: auto;
    margin: 0.5rem;
  }
  .button-submit {
    background-color: #000;
    color: #fff;
  }
  .modal-content {
    position: relative;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .new-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: -moz-calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: -moz-calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    text-align: center;
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-size: 1.5rem;
    }
    p {
      margin-bottom: 0px;
      line-height: 8px;
      color: #fff !important;
    }
    p:nth-child(1) {
      margin: 14px 0 16px;
    }
    p:nth-child(2) {
      margin: 5px 0;
      font-size: 16px;
    }
    p:nth-child(3) {
      margin: 15px 0 8px;
      font-size: 13px;
    }
  }
  .modal-body {
    padding: 10px;
    position: relative;
    flex: 1 1 auto;
  }
}

@media screen and (max-width: 768px) {
  .modal-header {
    font-size: 14px;
  }
}
